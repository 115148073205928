import React from 'react'
import "../style/resources.less"
import { navigate } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import arrowRight from "../images/resources/arrowRight.png"
// import video from "../images/resources/video.png"
import video from "../video/video12.mp4"
import arrowDown from "../images/resources/arrowDown.png"
// import { Button, message } from 'antd';
import { ToastContainer,toast } from 'react-toastify' 
import 'react-toastify/dist/ReactToastify.css';
import Fade from "react-reveal/Fade"
export default function Resources() {
    const key = 'updatable';
    // const openMessage = () => {
    //     message.loading({
    //       content: 'Loading...',
    //       key,
    //     });
    //     setTimeout(() => {
    //       message.success({
    //         content: 'Loaded!',
    //         key,
    //         duration: 2,
    //       });
    //     }, 1000);
    //   };
    return (
        <>
        <div className='resources'>
            <Header />
            <ul className='video_part'>
                <li>
                    <Fade distance={"20%"} top duration={1300} top>
                    <h3>Learn more about Datatrusts and DTaaS</h3>
                    </Fade>
                    <p>Data collaboration, value creation, analytics and governance, all in one secure platform.</p>
                    <div style={{ cursor: "pointer" }} onClick={() => navigate("/resourcesFaq")} className='more'>More <img src={arrowRight} alt="" /></div>

                </li>
                <li >
                    {/* <img src={video} alt="" /> */}
                    <video controls src={video}></video>
                </li>
            </ul>
            <ul className='introduction'>
                {
                    [0].map(item => {
                        return (
                            <li>

                                <div className='text'>
                                    <h3>Sightline Datatrust Introduction</h3>
                                    <p>Sightline enables organizations to securely collaborate with data while maintaining compliance and governance.</p>
                                </div>
                                <div className='down' onClick={()=>toast.success('Download succeeded!')}>
                                    <img src={arrowDown} alt="" />
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
        </div>
        <Footer />
        <ToastContainer
                style={{'zIndex':9999}}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}
